import Link from "next/link";

export default function AlertBanner() {
  return (
    <div className="relative isolate flex items-center justify-center gap-x-6 overflow-hidden bg-brand-pink px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap gap-y-2 gap-x-4 items-center">
        <p className="hidden text-sm leading-6 text-white md:inline">
          <strong className="font-semibold">
            &apos;Tis the season with Allyson Gofton
          </strong>
        </p>
        <p className="text-sm leading-6 text-white md:hidden">
          Join us for a festive evening of Christmas deliciousness and holiday
          gift ideas with beloved chef and baker Allyson Gofton.
        </p>
        <Link
          href="https://buytickets.at/thecakedetectivecharitabletrust/1355724"
          className="flex-none rounded-full bg-brand-navy px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-brand-navy/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#00b32c]"
        >
          Buy tickets here <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
    </div>
  );
}
